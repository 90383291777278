import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

// components
import SEO from '@components/SEO'
import Hero from './components/Hero'

import { termsConditionContent } from './modules/'

const TermsCondition = styled(({ className }) => {

 const termsConditionSeo         = termsConditionContent()[0]
 const termsConditionHero        = termsConditionContent()[1]
 const termsConditionPageElement = termsConditionContent()[2]

  return (
    <div className={`${className}`}>
      <SEO title={termsConditionSeo.title} description={termsConditionSeo.description} />
      <div className="bg-alabaster">
        <section className="d-inline-block w-100">
          <Hero data={termsConditionHero} />
        </section>
      </div>
      <section className="termsCondition-detail-inner">
        <div className="container">
          {termsConditionPageElement.map((item, key) => (
            <div className="item" key={key}>
              <h5>{item.title}</h5>
              {item.ptext.map((item, key) => (
                <p key={key}>{item.paragraf}</p>
              ))}
            </div>
          ))}
        </div>
      </section>
    </div>
  )
})`

  .termsCondition-detail-inner {
    background-color: #fafafa;
    position: relative;
    padding: 40px 0 29px;

    .item{
      padding: 0 7% 36px;

      h5{
        color: #000000;
        font-family: Roboto;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin-bottom: 24px;
      }

      p {
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.8px;
        color: #8e8e8e;
        margin-bottom: 24px;
      }
    }

    @media (min-width: 768px) {
      padding: 70px 0 6px 0;
    }
  }
`

TermsCondition.propTypes = {
  data: object.isRequired
}

TermsCondition.defaultProps = {
  data: {}
}

export default TermsCondition
