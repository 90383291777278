import { useStaticQuery, graphql } from 'gatsby'

export const termsConditionContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "656", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              __typename
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Headerdescriptionblock {
                content
                fieldGroupName
                title
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Contentblockdata {
                fieldGroupName
                contentList {
                  title
                  ptext {
                    paragraf
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return [
    data.wpgraphql.page.AllPageElements.pageelements[0],
    data.wpgraphql.page.AllPageElements.pageelements[1],
    data.wpgraphql.page.AllPageElements.pageelements[2].contentList
  ]
}