import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// components
import Hr from '@components/Hr'

const Hero = styled(({ data, className }) => (
  <div
    className={`${className} bg-cerulean-blue d-flex align-items-center position-relative`}
  >
    <Container>
      <Row>
        <Col xs={12} lg={7}>
          <div className="wrapper">
            <h1 className="text-white">{data.title}</h1>
            <Hr />
            <div
              className="text-white content"
              dangerouslySetInnerHTML={{ __html: data.content }}
            ></div>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
))`
  position: relative;
  padding: 22px 21px 29px;

  @media (min-width: 992px) {
    padding: 49px 25px 100px;
  }

  @media (min-width: 1440px) {
    padding: 87px 0 175px;
  }

  &:before {
    background: #f5c141;
    bottom: 0;
    clip-path: polygon(0 0, 0% 100%, 100% 100%);
    content: '';
    height: 72px;
    left: 0;
    position: absolute;
    width: 95px;
    z-index: 1;

    @media (min-width: 992px) {
      bottom: -90px;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      height: 141px;
      width: 160px;
    }

    @media (min-width: 1440px) {
      bottom: -240px;
      height: 328px;
      width: 373px;
    }
  }

  .wrapper {

    h1 {
      color: #ffffff;
      font-family: Roboto;
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      margin-bottom: 16px;

      @media (min-width: 992px) {
        font-size: 48px;
        line-height: 1;
      }
    }

    hr {
      height: 1px;
      margin-bottom: 16px;
    }

    .content {
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: 1px;
      margin-bottom: 16px;

      @media (min-width: 992px) {
        font-size: 14px;
        line-height: 1.29;
        letter-spacing: 0.6px;
        padding-right: 15px;
        margin-bottom: 0;

        p{
          margin-bottom: 0;
        }
      }
    }
  }
`

Hero.propTypes = {
  data: object.isRequired
}

Hero.defaultProps = {
  data: {}
}

export default Hero
