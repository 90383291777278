import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import TermsCondition from '@compositions/TermsCondition'

const TermsConditionPage = () => {
  return (
    <Layout>
      <TermsCondition />
    </Layout>
  )
}

export default TermsConditionPage
